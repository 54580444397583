/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import {
  DEFAULT_ALL_PAGE_SIZE,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
} from '../../config';
import { LOGOUT_DONE } from '../Auth/constants';
import {
  CHANGE_AUTO_ACCEPT,
  CHANGE_AUTO_PILOT,
  CHANGE_BIZ,
  CHANGE_INTEGRATION_TYPE,
  CHANGE_IS_SINGLE_DEVICE,
  CHANGE_MENU_SYNC,
  CHANGE_PROVIDER,
  DELETE_PROVIDER,
  DELETE_PROVIDER_FAILED,
  DELETE_PROVIDER_SUCCESS,
  DESELECT_LOCATION,
  DESELECT_PROVIDER,
  GET_PROVIDER_CRED_SUCCESS,
  LOAD_BRANDS_FILTER_SUCCESS,
  LOAD_BRANDS_SUCCESS,
  LOAD_BUSINESS_SUCCESS,
  LOAD_LOCATION,
  LOAD_LOCATION_BY_ID,
  LOAD_LOCATION_BY_ID_FAILED,
  LOAD_LOCATION_FAILED,
  LOAD_LOCATION_SUCCESS,
  LOAD_PROVIDERS,
  LOAD_PROVIDER_FAILED,
  LOAD_PROVIDER_SUCCESS,
  LOAD_TIED_PROVIDER_SUCCESS,
  LOCATION_SELECTED,
  SELECTED_BRAND,
  UPDATE_BRAND_STATUS,
  UPDATE_BRAND_STATUS_FAILED,
  UPDATE_BRAND_STATUS_SUCCESS,
  UPDATE_PAGINATION,
  UPDATE_PROVIDER_CRED,
  UPDATE_PROVIDER_CRED_FAILED,
  UPDATE_PROVIDER_CRED_SUCCESS,
  SEND_OTP_REQUEST_FOR_GOFOOD,
  SEND_OTP_REQUEST_FOR_GOFOOD_SUCCESS,
  SEND_OTP_REQUEST_FOR_GOFOOD_FAILED,
  VERIFY_OTP_TOKEN_FOR_GOFOOD,
  VERIFY_OTP_TOKEN_FOR_GOFOOD_SUCCESS,
  VERIFY_OTP_TOKEN_FOR_GOFOOD_FAILED,
  LOGIN_SHOPEE_CREDENTIALS,
  LOGIN_SHOPEE_CREDENTIALS_SUCCESS,
  LOGIN_SHOPEE_CREDENTIALS_FAILED,
  GET_UBEREATS_LOGIN_URL_SUCCESS,
  GET_UBEREATS_LOGIN_URL,
  GET_UBEREATS_LOGIN_URL_FAILED,
  GET_AVAILABLE_UBEREATS_STORES_SUCCESS,
  CHANGE_UBEREATS_STORE,
  GET_AVAILABLE_UBEREATS_STORES_FAILED,
  GET_AVAILABLE_UBEREATS_STORES,
  GET_SQUARE_LOGIN_URL,
  GET_SQUARE_LOGIN_URL_SUCCESS,
  GET_SQUARE_LOGIN_URL_FAILED,
  GET_AVAILABLE_SQUARE_STORES,
  GET_AVAILABLE_SQUARE_STORES_SUCCESS,
  GET_AVAILABLE_SQUARE_STORES_FAILED,
  CHANGE_SQUARE_STORE,
  UPLOAD_CSV,
  UPLOAD_CSV_SUCCESS,
  UPLOAD_CSV_FAILED,
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILED,
  CHANGE_SETTINGS,
  EDIT_FIELD,
  LOAD_LIST,
  LOAD_LIST_SUCCESS,
  LOAD_LIST_FAILED,
  DELETE_SETTINGS,
  SETTINGS_RESOLVED,
  SETTINGS_REJECTED,
  RESET_FIELDS,
  CREATE_SETTINGS,
  UPDATE_SETTINGS,
} from './constants';

export const initialState = {
  locationLoading: false,
  locations: {
    list: [],
    total: 0,
    page: DEFAULT_PAGE_NO,
    size: DEFAULT_ALL_PAGE_SIZE,
  },
  selectedLocation: null,
  brandsListWithStatus: [],
  brands: [],
  locationWiseBrands: [],
  brandStatusUpdateLoading: false,
  selectedBrand: null,
  pagination: {
    pageNo: DEFAULT_PAGE_NO,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  brandsListLoading: true,
  businesses: [],
  brandsFilter: [],
  business: null,
  providers: [],
  tiedProviders: [],
  providersLoading: false,
  provider: null,
  showV1Form: false,
  updateProviderCredLoading: false,
  providerCred: null,
  autoAccept: true,
  selectedIntegrationType: 'api',
  autoPilot: true,
  deleteProviderLoading: false,
  menuSync: true,
  isSingleDevice: false,
  isOTPFieldVisibleForGoFood: false,
  otpTokenForGoFood: null,
  verifyOTPTokenForGoFoodSendSuccessful: false,
  verifyOTPTokenForGoFoodLoading: false,
  sendOTPRequestForGoFoodLoading: false,
  shopeeOTPPhone: null,
  isOTPFieldVisibleForShopee: false,
  loginShopeeCredentialsLoading: false,
  loginShopeeCredentialsSuccessful: false,
  uberEatsLoginUrl: '',
  uberEatsLoginRequired: false,
  getUberEatsLoginUrlLoading: false,
  isUberEatsLoginSuccessful: false,
  availableUberEatsStores: null,
  selectedUberEatsStore: null,
  getUberEatsStoreLoading: false,
  squareLoginUrl: '',
  getSquareLoginUrlLoading: false,
  isSquareLoginSuccessful: false,
  availableSquareStores: null,
  selectedSquareStore: null,
  getSquareStoreLoading: false,
  csvLoading: false,
  goFoodLoginUrl: '',
  isSettingsLoading: false,
  goFoodStoreList: [],
  selectedGoFoodStore: null,
  aggregatorDetails: {},
  isLoading: false,
  isEsbBranchesLoading: false,
  uberEatsStoreList: [],
  selectedAggregator: null,
  shopeeLoginCredentials: {},
  squareStoreList: [],
  goFoodOTP: null,
  showV2Form: false,
  isActionLoading: false,
  tiktokLoginUrl: '',
  tiktokStores: {},
  countryList: [],
  cityDetails: {},
  posProviders: [],
  tiedPosProviders: [],
};

const integrationReducer = (state = initialState, action) =>
  produce(state, draft => {
    const actionParamKeys = Object.keys(action.params || {});
    switch (action.type) {
      case LOCATION_SELECTED:
        draft.selectedLocation = action.selectedLocation;
        draft.brandsListWithStatus = action.brandsListWithStatus;
        draft.locationWiseBrands = action.locationWiseBrands;
        draft.brandsListLoading = false;
        break;
      case LOAD_LOCATION:
        draft.locationLoading = true;
        break;
      case LOAD_LOCATION_SUCCESS:
        draft.locations = action.locations;
        draft.locationLoading = false;
        break;
      case LOAD_LOCATION_FAILED:
        draft.locationLoading = false;
        break;
      case LOAD_BRANDS_SUCCESS:
        draft.brands = action.brands;
        break;
      case UPDATE_BRAND_STATUS:
        draft.brandStatusUpdateLoading = true;
        break;
      case UPDATE_BRAND_STATUS_SUCCESS:
        draft.brandStatusUpdateLoading = false;
        break;
      case UPDATE_BRAND_STATUS_FAILED:
        draft.brandStatusUpdateLoading = false;
        break;
      case SELECTED_BRAND:
        draft.selectedBrand = action.brand;
        break;
      case UPDATE_PAGINATION:
        draft.pagination.pageNo = action.pageNo;
        draft.pagination.pageSize = action.pageSize;
        break;
      case LOAD_LOCATION_BY_ID:
        draft.brandsListLoading = true;
        break;
      case LOAD_LOCATION_BY_ID_FAILED:
        draft.brandsListLoading = false;
        break;
      case DESELECT_LOCATION:
        draft.selectedLocation = initialState.selectedLocation;
        draft.brandsListWithStatus = initialState.brandsListWithStatus;
        draft.locationWiseBrands = initialState.locationWiseBrands;
        break;
      case LOAD_BUSINESS_SUCCESS:
        draft.businesses = action.businesses;
        break;
      case LOAD_BRANDS_FILTER_SUCCESS:
        draft.brandsFilter = action.brandsFilter;
        break;
      case CHANGE_BIZ:
        draft.business = action.business;
        break;
      case CHANGE_PROVIDER:
        draft.provider = action.provider;
        draft.isOTPFieldVisibleForShopee = false;
        draft.shopeeOTPPhone = null;
        draft.isOTPFieldVisibleForGoFood = false;
        draft.loginShopeeCredentialsSuccessful = false;
        draft.isUberEatsLoginSuccessful = false;
        draft.uberEatsLoginUrl = initialState.uberEatsLoginUrl;
        draft.availableUberEatsStores = initialState.availableUberEatsStores;
        draft.squareLoginUrl = initialState.squareLoginUrl;
        draft.goFoodLoginUrl = initialState.goFoodLoginUrl;
        draft.availableSquareStores = initialState.availableSquareStores;
        draft.selectedSquareStore = initialState.selectedSquareStore;
        draft.goFoodStoreList = initialState.goFoodStoreList;
        break;
      case DESELECT_PROVIDER:
        draft.provider = initialState.provider;
        draft.providerCred = initialState.providerCred;
        draft.showV1Form = false;
        draft.showV2Form = false;
        break;
      case LOAD_PROVIDERS:
        draft.providersLoading = true;
        break;
      case LOAD_PROVIDER_SUCCESS:
        draft.providers = action.providers;
        draft.providersLoading = false;
        break;
      case LOAD_PROVIDER_FAILED:
        draft.providersLoading = false;
        break;
      case LOAD_TIED_PROVIDER_SUCCESS:
        draft.tiedProviders = action.tiedProviders;
        break;
      case UPDATE_PROVIDER_CRED:
        draft.updateProviderCredLoading = true;
        break;
      case UPDATE_PROVIDER_CRED_FAILED:
        draft.updateProviderCredLoading = false;
        break;
      case UPDATE_PROVIDER_CRED_SUCCESS:
        draft.updateProviderCredLoading = false;
        draft.providerCred = action.providerCred;
        draft.isOTPFieldVisibleForGoFood = false;
        draft.isOTPFieldVisibleForShopee = false;
        draft.shopeeOTPPhone = null;
        draft.goFoodStoreList = initialState.goFoodStoreList;
        draft.selectedGoFoodStore = initialState.selectedGoFoodStore;
        draft.goFoodLoginUrl = initialState.goFoodLoginUrl;
        break;
      case GET_PROVIDER_CRED_SUCCESS:
        draft.providerCred = action.providerCred;
        draft.isOTPFieldVisibleForGoFood = false;
        draft.autoAccept = action?.providerCred?.auto_accept;
        break;
      case CHANGE_AUTO_ACCEPT:
        draft.autoAccept = action.autoAccept;
        break;
      case CHANGE_INTEGRATION_TYPE:
        draft.selectedIntegrationType = action.selectedIntegrationType;
        break;
      case CHANGE_AUTO_PILOT:
        draft.autoPilot = action.autoPilot;
        break;
      case CHANGE_MENU_SYNC:
        draft.menuSync = action.menuSync;
        break;
      case DELETE_PROVIDER:
        draft.deleteProviderLoading = true;
        break;
      case DELETE_PROVIDER_SUCCESS:
        draft.deleteProviderLoading = initialState.deleteProviderLoading;
        draft.uberEatsLoginUrl = initialState.uberEatsLoginUrl;
        draft.uberEatsLoginRequired = initialState.uberEatsLoginRequired;
        draft.getUberEatsLoginUrlLoading =
          initialState.getUberEatsLoginUrlLoading;
        draft.isUberEatsLoginSuccessful =
          initialState.isUberEatsLoginSuccessful;
        draft.availableUberEatsStores = initialState.availableUberEatsStores;
        draft.selectedUberEatsStore = initialState.selectedUberEatsStore;
        draft.goFoodStoreList = initialState.goFoodStoreList;
        draft.selectedGoFoodStore = initialState.selectedGoFoodStore;
        draft.goFoodLoginUrl = initialState.goFoodLoginUrl;
        draft.providerCred = initialState.providerCred;
        draft.isOTPFieldVisibleForGoFood =
          initialState.isOTPFieldVisibleForGoFood;
        draft.otpTokenForGoFood = initialState.otpTokenForGoFood;
        draft.verifyOTPTokenForGoFoodSendSuccessful =
          initialState.verifyOTPTokenForGoFoodSendSuccessful;
        draft.verifyOTPTokenForGoFoodLoading =
          initialState.verifyOTPTokenForGoFoodLoading;
        draft.sendOTPRequestForGoFoodLoading =
          initialState.sendOTPRequestForGoFoodLoading;
        break;
      case DELETE_PROVIDER_FAILED:
        draft.deleteProviderLoading = initialState.deleteProviderLoading;
        break;
      case CHANGE_IS_SINGLE_DEVICE:
        draft.isSingleDevice = action.isSingleDevice;
        break;
      case SEND_OTP_REQUEST_FOR_GOFOOD:
        draft.isOTPFieldVisibleForGoFood = false;
        draft.sendOTPRequestForGoFoodLoading = true;
        break;
      case SEND_OTP_REQUEST_FOR_GOFOOD_SUCCESS:
        draft.isOTPFieldVisibleForGoFood = true;
        draft.otpTokenForGoFood = action.otpTokenForGoFood;
        draft.sendOTPRequestForGoFoodLoading = false;
        break;
      case SEND_OTP_REQUEST_FOR_GOFOOD_FAILED:
        draft.isOTPFieldVisibleForGoFood = false;
        draft.sendOTPRequestForGoFoodLoading = false;
        break;
      case VERIFY_OTP_TOKEN_FOR_GOFOOD:
        draft.verifyOTPTokenForGoFoodSendSuccessful = false;
        draft.verifyOTPTokenForGoFoodLoading = true;
        break;
      case VERIFY_OTP_TOKEN_FOR_GOFOOD_SUCCESS:
        draft.verifyOTPTokenForGoFoodSendSuccessful = true;
        draft.verifyOTPTokenForGoFoodLoading = false;
        break;
      case VERIFY_OTP_TOKEN_FOR_GOFOOD_FAILED:
        draft.makeSelectIsOTPFieldVisibleForGoFood = false;
        draft.verifyOTPTokenForGoFoodLoading = false;
        break;
      case LOGIN_SHOPEE_CREDENTIALS:
        draft.loginShopeeCredentialsLoading = true;
        break;
      case LOGIN_SHOPEE_CREDENTIALS_SUCCESS:
        draft.isOTPFieldVisibleForShopee =
          (action.shopeeCredentials && action.shopeeCredentials.otp_required) ||
          false;
        draft.shopeeOTPPhone =
          (action.shopeeCredentials && action.shopeeCredentials.phone) || null;
        draft.loginShopeeCredentialsLoading = false;
        draft.loginShopeeCredentialsSuccessful = true;
        break;
      case LOGIN_SHOPEE_CREDENTIALS_FAILED:
        draft.loginShopeeCredentialsLoading = false;
        draft.loginShopeeCredentialsSuccessful = false;
        break;
      case GET_UBEREATS_LOGIN_URL:
        draft.getUberEatsLoginUrlLoading = true;
        break;
      case GET_UBEREATS_LOGIN_URL_SUCCESS:
        draft.uberEatsLoginUrl = action?.uberEatsLoginData?.login_url;
        draft.uberEatsLoginRequired = action?.uberEatsLoginData?.login_required;
        draft.getUberEatsLoginUrlLoading = false;
        draft.isUberEatsLoginSuccessful = true;
        break;
      case GET_UBEREATS_LOGIN_URL_FAILED:
        draft.getUberEatsLoginUrlLoading = false;
        draft.isUberEatsLoginSuccessful = false;
        break;
      case GET_SQUARE_LOGIN_URL:
        draft.getSquareLoginUrlLoading = true;
        break;
      case GET_SQUARE_LOGIN_URL_SUCCESS:
        draft.squareLoginUrl = action?.squareLoginUrl;
        draft.getSquareLoginUrlLoading = false;
        draft.isSquareLoginSuccessful = true;
        break;
      case GET_SQUARE_LOGIN_URL_FAILED:
        draft.getSquareLoginUrlLoading = false;
        draft.isSquareLoginSuccessful = false;
        break;
      case GET_AVAILABLE_UBEREATS_STORES:
        draft.getUberEatsStoreLoading = true;
        break;
      case GET_AVAILABLE_UBEREATS_STORES_SUCCESS:
        draft.availableUberEatsStores = action.availableUberEatsStores;
        draft.getUberEatsStoreLoading = false;
        break;
      case GET_AVAILABLE_UBEREATS_STORES_FAILED:
        draft.availableUberEatsStores = initialState.availableUberEatsStores;
        draft.getUberEatsStoreLoading = false;
        break;
      case CHANGE_UBEREATS_STORE:
        draft.selectedUberEatsStore = action.selectedUberEatsStore;
        break;
      case GET_AVAILABLE_SQUARE_STORES:
        draft.getSquareStoreLoading = true;
        break;
      case GET_AVAILABLE_SQUARE_STORES_SUCCESS:
        draft.availableSquareStores = action.availableSquareStores;
        draft.getSquareStoreLoading = false;
        break;
      case GET_AVAILABLE_SQUARE_STORES_FAILED:
        draft.getSquareStoreLoading = false;
        break;
      case CHANGE_SQUARE_STORE:
        draft.selectedSquareStore = action.selectedSquareStore;
        break;
      case UPLOAD_CSV:
        draft.csvLoading = true;
        break;
      case UPLOAD_CSV_SUCCESS:
        draft.csvLoading = false;
        break;
      case UPLOAD_CSV_FAILED:
        draft.csvLoading = false;
        break;
      case GET_SETTINGS:
        draft.isSettingsLoading = true;
        break;
      case GET_SETTINGS_SUCCESS:
        draft.isSettingsLoading = false;
        draft[action.payload.key] = action.payload.value;
        break;
      case GET_SETTINGS_FAILED:
        draft.isSettingsLoading = false;
        draft[action.payload.key] = initialState[action.payload.key];
        break;
      case CHANGE_SETTINGS:
        draft[action.payload.key] = action.payload.value;
        break;
      case EDIT_FIELD:
        actionParamKeys.forEach(k => {
          draft[k] = action.params[k];
        });
        break;
      case LOAD_LIST:
        draft[action.meta?.loaderKey || 'isLoading'] = true;
        break;
      case LOAD_LIST_SUCCESS:
      case LOAD_LIST_FAILED:
        draft[action.meta?.loaderKey || 'isLoading'] = false;
        break;
      case CREATE_SETTINGS:
      case UPDATE_SETTINGS:
      case DELETE_SETTINGS:
        draft.isActionLoading = true;
        break;
      case SETTINGS_RESOLVED:
        draft.isActionLoading = false;
        break;
      case SETTINGS_REJECTED:
        draft.isActionLoading = false;
        break;
      case RESET_FIELDS: {
        const { keys } = action.params;
        keys.forEach(key => {
          draft[key] = initialState[key];
        });
        break;
      }
      case LOGOUT_DONE:
        draft.locationLoading = initialState.locationLoading;
        draft.locations = initialState.locations;
        draft.selectedLocation = initialState.selectedLocation;
        draft.brandsListWithStatus = initialState.brandsListWithStatus;
        draft.brands = initialState.brands;
        draft.locationWiseBrands = initialState.locationWiseBrands;
        draft.brandStatusUpdateLoading = initialState.brandStatusUpdateLoading;
        draft.selectedBrand = initialState.selectedBrand;
        draft.pagination = initialState.pagination;
        draft.brandsListLoading = initialState.brandsListLoading;
        draft.businesses = initialState.businesses;
        draft.brandsFilter = initialState.brandsFilter;
        draft.business = initialState.business;
        draft.providers = initialState.providers;
        draft.tiedProviders = initialState.tiedProviders;
        draft.updateProviderCredLoading =
          initialState.updateProviderCredLoading;
        draft.providerCred = initialState.providerCred;
        draft.autoAccept = initialState.autoAccept;
        draft.selectedIntegrationType = initialState.selectedIntegrationType;
        draft.autoPilot = initialState.autoPilot;
        draft.menuSync = initialState.menuSync;
        draft.deleteProviderLoading = initialState.deleteProviderLoading;
        draft.isSingleDevice = initialState.isSingleDevice;
        draft.isOTPFieldVisibleForGoFood =
          initialState.isOTPFieldVisibleForGoFood;
        draft.otpTokenForGoFood = initialState.otpTokenForGoFood;
        draft.verifyOTPTokenForGoFoodSendSuccessful =
          initialState.verifyOTPTokenForGoFoodSendSuccessful;
        draft.verifyOTPTokenForGoFoodLoading =
          initialState.verifyOTPTokenForGoFoodLoading;
        draft.sendOTPRequestForGoFoodLoading =
          initialState.sendOTPRequestForGoFoodLoading;
        draft.isOTPFieldVisibleForShopee =
          initialState.isOTPFieldVisibleForShopee;
        draft.shopeeOTPPhone = initialState.shopeeOTPPhone;
        draft.loginShopeeCredentialsLoading =
          initialState.loginShopeeCredentialsLoading;
        draft.loginShopeeCredentialsSuccessful =
          initialState.loginShopeeCredentialsSuccessful;
        draft.isUberEatsLoginSuccessful =
          initialState.isUberEatsLoginSuccessful;
        draft.uberEatsLoginUrl = initialState.uberEatsLoginUrl;
        draft.uberEatsLoginRequired = initialState.uberEatsLoginRequired;
        draft.getUberEatsLoginUrlLoading =
          initialState.getUberEatsLoginUrlLoading;
        draft.availableUberEatsStores = initialState.availableUberEatsStores;
        draft.selectedUberEatsStore = initialState.selectedUberEatsStore;
        draft.squareLoginUrl = initialState.squareLoginUrl;
        draft.getSquareLoginUrlLoading = initialState.getSquareLoginUrlLoading;
        draft.isSquareLoginSuccessful = initialState.isSquareLoginSuccessful;
        draft.availableSquareStores = initialState.availableSquareStores;
        draft.selectedSquareStore = initialState.selectedSquareStore;
        draft.getSquareStoreLoading = initialState.getSquareStoreLoading;
        break;
      default:
    }
  });
export default integrationReducer;
