import { PROVIDERS } from '../env';

export const NATIVE_ANALYTICS_SAGA = {
  OOS_REPORT_ITEM: {
    KEY: 'itemReportData',
    GET: 'v2/analyseit/menu/item/oos/analytics',
  },
  OOS_REPORT_MODIFIER: {
    KEY: 'modifierReportData',
    GET: 'v2/analyseit/menu/modifier/oos/analytics',
  },
  OOS_LOGS_ITEM: {
    KEY: 'itemLogData',
    GET: 'v2/analyseit/menu/item/oos/logs',
  },
  OOS_LOGS_MODIFIER: {
    KEY: 'modifierLogData',
    GET: 'v2/analyseit/menu/modifier/oos/logs',
  },
  COMPARISON_BRANDS_CHART_ONE: {
    KEY: 'comparisonBrandsChartOne',
    GET: 'v1/brand',
  },
  COMPARISON_BRANDS_CHART_TWO: {
    KEY: 'comparisonBrandsChartTwo',
    GET: 'v1/brand',
  },
};

export const REVENUE_KEYS = {
  GROSS_REVENUE: 'gross_revenue',
  GROSS_REVENUE_USD: 'gross_revenue_usd',
  LOST_REVENUE: 'lost_revenue',
  LOST_REVENUE_USD: 'lost_revenue_usd',
  REALIZED_REVENUE: 'realized_revenue',
  REALIZED_REVENUE_USD: 'realized_revenue_usd',
  NET_REVENUE: 'net_revenue',
  NET_REVENUE_USD: 'net_revenue_usd',
};

export const ORDER_COUNT_KEYS = {
  COMPLETED_ORDERS: 'completed_orders',
  CANCELLED_ORDERS: 'cancelled_orders',
  TOTAL_ORDERS: 'total_orders',
};

export const ORDER_CANCELLATION_RATE_KEY = 'cancellation_rate';

export const AVG_BASKET_KEYS = {
  COMPLETED_BASKET_SIZE: 'avg_completed_basket_size',
  CANCELLED_BASKET_SIZE: 'avg_cancelled_basket_size',
};

export const BUSY_MODE_KEYS = {
  BUSY_MINUTES: 'busy_mins',
};

export const TIME_PERIOD_LIST = [
  { title: 'Hourly', id: 'hourly', slug: 'hourly' },
  { title: 'Daily', id: 'daily', slug: 'daily' },
  { title: 'Weekly', id: 'daily', slug: 'weekly' },
  { title: 'Monthly', id: 'daily', slug: 'monthly' },
];

export const DEFAULT_TIME_PERIOD = TIME_PERIOD_LIST[1];
export const HOURLY_TIME_PERIOD = TIME_PERIOD_LIST[0].slug;
export const DAILY_TIME_PERIOD = TIME_PERIOD_LIST[1].slug;
export const WEEKLY_TIME_PERIOD = TIME_PERIOD_LIST[2].slug;
export const MONTHLY_TIME_PERIOD = TIME_PERIOD_LIST[3].slug;

export const DATE_FILTER_DAY_LIMIT = 365;

export const ITEM_BREAKDOWN_TABS = [
  {
    id: 1,
    key: 'items',
    title: 'Items',
  },
  {
    id: 2,
    key: 'categories',
    title: 'Categories',
  },
  {
    id: 3,
    key: 'modifiers',
    title: 'Modifiers',
  },
];
export const ANALYTICS_HEATMAP_RANGE_COLORS = [
  '#C3A2FB',
  '#A572F8',
  '#6C35C3',
  '#6A13F4',
];
export const ANALYTICS_HEATMAP_DEFAULT_COLOR = '#FFFFFF';

export const ANALYTICS_PROVIDER_COMPARISON_COLOR_CODE = {
  [PROVIDERS.KLIKIT]: '#6a13f4',
  [PROVIDERS.UBEREATS]: '#57bd71',
  [PROVIDERS.DELIVEROO]: '#2596be',
  [PROVIDERS.JUSTEAT]: '#F39F18',
  [PROVIDERS.DOORDASH]: '#e75637',
  [PROVIDERS.GRABFOOD]: '#269342',
  [PROVIDERS.FOODPANDA]: '#de2673',
  [PROVIDERS.SHOPIFY]: '#8673A1',
  [PROVIDERS.GOFOOD]: '#ff0b0b',
  [PROVIDERS.ODDLE]: '#3b0693',
  [PROVIDERS.SHOPEE]: '#ff8f0b',
  12: '#f37934',
  [PROVIDERS.PICKAROO]: '#1de1ce',
  [PROVIDERS.WOLT]: '#00C2E8',
  [PROVIDERS.SQUARE]: '#000000',
  16: '#A98307',
  17: '#231A24',
  18: '#5E2129',
  19: '#1E2460',
  20: '#B32821',
  21: '#3E3B32',
  22: '#1E5945',
};
export const SUMMARY_CHART_BREAKPOINTS = (() => {
  const rsBreakpoints = [
    {
      breakpoint: 992,
      options: {
        chart: {
          width: 240,
        },
      },
    },
  ];
  let res = 1920;
  let highWidth = 250;
  let lowWidth = 240;

  while (res > 300) {
    const width = res > 992 ? highWidth : lowWidth;
    rsBreakpoints.push({
      breakpoint: res,
      options: {
        chart: {
          width,
        },
      },
    });

    res -= 64;

    if (res > 992) {
      highWidth -= 8;
    } else {
      lowWidth -= 15;
    }
  }

  return rsBreakpoints;
})();

export const DISCOUNT_KEYS = {
  MERCHANT_DISCOUNT: 'merchant_discount',
  PROVIDER_DISCOUNT: 'provider_discount',
  TOTAL_DISCOUNT: 'discount',
};

export const OWN_CHANNEL_KEYS = [
  { title: 'Add Order', id: 'm', slug: 'm' },
  { title: 'Webshop', id: 'w', slug: 'w' },
  { title: 'Fast Charge', id: 's', slug: 's' },
];

export const DEFAULT_CURRENCY_OBJ = { code: 'USD', symbol: '$' };

export const COMPARISON_TYPE = {
  AGGREGATOR: 'provider_id',
  BRAND: 'brand_id',
  BRANCH: 'branch_id',
};

export const OOS_TABS = [
  { id: 1, title: 'OOS Report', type: 'analytics' },
  { id: 2, title: 'OOS Logs', type: 'logs' },
];

export const OOS_TABLE_TYPE = {
  ITEMS: {
    id: 0,
    label: 'Items',
    title: 'item',
  },
  MODIFIERS: {
    id: 1,
    label: 'Modifiers',
    title: 'modifier',
  },
};

export const PERFORMANCE_TYPES = {
  BRAND_PERFORMANCE: 'brand_performance',
  BRANCH_PERFORMANCE: 'branch_performance',
  BRANCH_COMPARISON: 'branch_comparison',
};

export const ANALYTICS_CHART_COLORS = [
  '#993300',
  '#16B050',
  '#FFCC00',
  '#993366',
  '#6A13F4',
  '#86862F',
  '#F43F5E',
  '#FF6600',
  '#740000',
  '#008000',
  '#008080',
  '#1B4ED2',
  '#FF9900',
  '#FF00FF',
  '#6F520A',
  '#2FFF2F',
  '#9123FF',
  '#99CCFF',
  '#A78407',
  '#FF99CC',
  '#00A896',
  '#FFC857',
  '#2A7886',
  '#E63946',
  '#F4A261',
  '#4A4E4D',
  '#06D6A0',
  '#FC4445',
  '#247BA0',
  '#F3722C',
  '#9E0DFF',
  '#F7DB4F',
  '#457B9D',
  '#D7263D',
  '#FFE74C',
  '#009688',
  '#FF5252',
  '#00B4D8',
  '#D64161',
  '#F0A6CA',
  '#2EC4B6',
  '#FFAA5E',
  '#61C0BF',
  '#FA7B62',
  '#A31621',
  '#FFD700',
  '#0E4DA4',
  '#F67280',
  '#344D56',
  '#F7DC6F',
  '#26547C',
  '#F8961E',
  '#7B8D8E',
  '#A29BFE',
  '#F87060',
  '#4B86B4',
  '#FF70A6',
  '#3A506B',
  '#FFD100',
  '#015668',
  '#FC766A',
  '#5BC0EB',
  '#F9DC5C',
  '#2E282A',
  '#DA4167',
  '#FF6F61',
  '#0496FF',
  '#F9844A',
  '#5F5AA2',
  '#FADA5E',
  '#247BA0',
  '#E63946',
  '#6F1E51',
  '#F4A261',
  '#D00000',
  '#536DFE',
  '#A5DFFA',
  '#EDEDED',
  '#FFAE03',
  '#506C7F',
  '#FDCB6E',
  '#708D81',
  '#FFB900',
  '#83C5BE',
  '#E7ECEF',
  '#F3EFE0',
  '#FDF6F0',
  '#FB637E',
  '#5C3C92',
  '#FEAA2D',
  '#4F6D7A',
  '#FFCD3C',
  '#3A6351',
  '#FF7300',
  '#338EA7',
  '#FDCB6E',
  '#3F72AF',
  '#FF8C42',
  '#004D40',
];
