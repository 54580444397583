import { compareAsc, getWeek, isValid, parseISO } from 'date-fns';
import {
  MONTHLY_TIME_PERIOD,
  ORDER_COUNT_KEYS,
  REVENUE_KEYS,
  WEEKLY_TIME_PERIOD,
} from '../config/constants';
import {
  ANALYTICS_CHART_COLORS,
  ANALYTICS_PROVIDER_COMPARISON_COLOR_CODE,
  AVG_BASKET_KEYS,
  COMPARISON_TYPE,
  DEFAULT_CURRENCY_OBJ,
  DISCOUNT_KEYS,
  ORDER_CANCELLATION_RATE_KEY,
} from '../config/constants/analytics';
import { PROVIDERS } from '../config/env';
import { sortArrayByDate } from './arr-helpers';
import { centToMoney } from './getCurrency';
import { isEmpty } from './index';

const getDailyData = (targetKey, analyticsData) => {
  const dailyData = [];
  const hasAnalyticsData = analyticsData && analyticsData.length;
  if (hasAnalyticsData) {
    analyticsData.forEach(data => {
      const {
        date,
        completed_orders: completedOrders,
        cancelled_orders: cancelledOrders,
      } = data || {};
      const dateIndex =
        dailyData && dailyData.length
          ? dailyData.findIndex(d => Object.keys(d)[0] === date)
          : -1;
      const dateData = dailyData[dateIndex][date];
      if (dateIndex > -1) {
        const targetKeyIndex =
          dateData && dateData.length
            ? dateData.findIndex(dt => +Object.keys(dt)[0] === data[targetKey])
            : -1;
        if (targetKeyIndex > -1) {
          let targetWiseData = {
            ...dateData[targetKeyIndex][data[targetKey]],
          };
          targetWiseData = {
            completedOrders:
              (targetWiseData.completedOrders || 0) + completedOrders,
            cancelledOrders:
              (targetWiseData.cancelledOrders || 0) + cancelledOrders,
            totalOrders:
              (targetWiseData.completedOrders || 0) +
              (targetWiseData.cancelledOrders || 0) +
              completedOrders +
              cancelledOrders,
          };
          dateData[targetKeyIndex][data[targetKey]] = targetWiseData;
        } else {
          dateData.push({
            [data[targetKey]]: {
              completedOrders:
                ([data[targetKey]].completedOrders || 0) + completedOrders,
              cancelledOrders:
                ([data[targetKey]].cancelledOrders || 0) + cancelledOrders,
            },
          });
        }
      } else {
        dailyData.push({
          [date]: [
            {
              [data[targetKey]]: {
                completedOrders:
                  ([data[targetKey]].completedOrders || 0) + completedOrders,
                cancelledOrders:
                  ([data[targetKey]].cancelledOrders || 0) + cancelledOrders,
              },
            },
          ],
        });
      }
    });
  }
  return dailyData;
};

const findDateKey = data => {
  if (data.length === 0) {
    return null;
  }

  const dateKey = Object.keys(data[0]).find(
    k =>
      k.toLowerCase().includes('date') &&
      data.every(d => isValid(new Date(d[k]))),
  );
  return dateKey || null;
};

const getCumulativeSumForDailyData = (data, key) => {
  const dateKey = findDateKey(data);
  const dailySummaries = [];
  if (data && data.length && dateKey) {
    data.forEach(d => {
      const index = dailySummaries.findIndex(
        s => new Date(s[dateKey]).getTime() === new Date(d[dateKey]).getTime(),
      );
      if (index > -1) {
        dailySummaries[index].count += d[key];
      } else {
        dailySummaries.push({ [dateKey]: d[dateKey], count: d[key] });
      }
    });
  }
  const cumulativeSumData =
    dailySummaries && dailySummaries.length
      ? sortArrayByDate(dailySummaries, dateKey)
      : [];
  return cumulativeSumData;
};

const getCumulativeSumForWeeklyData = (data, key) => {
  const dateKey = findDateKey(data);
  const weeklySummaries = [];

  if (data && data.length && dateKey) {
    data.forEach(item => {
      const itemDate = new Date(item[dateKey]);
      const currentWeek = getWeek(itemDate, { weekStartsOn: 1 });

      const existingSummaryIndex = weeklySummaries.findIndex(
        summary => summary.week === currentWeek,
      );

      if (existingSummaryIndex > -1) {
        weeklySummaries[existingSummaryIndex].count += item[key];
      } else {
        weeklySummaries.push({
          [dateKey]: item[dateKey],
          week: currentWeek,
          count: item[key],
        });
      }
    });
  }

  const cumulativeSumData = weeklySummaries.length
    ? sortArrayByDate(weeklySummaries, dateKey)
    : [];
  return cumulativeSumData;
};

const getCumulativeSumForMonthlyData = (data, key) => {
  const dateKey = findDateKey(data);
  const monthlySummaries = [];
  if (data && data.length) {
    if (dateKey) {
      data.forEach(d => {
        const currentMonth = new Date(d[dateKey]).getMonth();
        const index = monthlySummaries.findIndex(s => s.month === currentMonth);
        if (index > -1) {
          monthlySummaries[index].count += d[key];
        } else {
          monthlySummaries.push({
            [dateKey]: d[dateKey],
            month: currentMonth,
            count: d[key],
          });
        }
      });
    }
  }
  const cumulativeSumData =
    monthlySummaries && monthlySummaries.length
      ? sortArrayByDate(monthlySummaries, dateKey)
      : [];
  return cumulativeSumData;
};

const getMonthName = monthNo => {
  const date = new Date();
  date.setMonth(monthNo);
  return date.toLocaleString('en-US', { month: 'short' });
};

const getTimePeriodKey = timePeriod => {
  if (timePeriod === WEEKLY_TIME_PERIOD) return 'week';
  if (timePeriod === MONTHLY_TIME_PERIOD) return 'month';
  return 'date';
};

const calculateTimePeriod = (timePeriod, date) => {
  if (timePeriod === WEEKLY_TIME_PERIOD) return getWeek(new Date(date));
  if (timePeriod === MONTHLY_TIME_PERIOD) return new Date(date).getMonth();
  return date;
};

const generateComparisonData = (analyticsData, groupByKey, timePeriod) => {
  const dynamicComparisonData = [];
  const hasAnalyticsData = analyticsData && analyticsData.length;
  if (hasAnalyticsData) {
    analyticsData.forEach(data => {
      const {
        date,
        [groupByKey]: groupKey, // Use dynamic key based on groupByKey parameter
        completed_orders: completedOrders,
        cancelled_orders: cancelledOrders,
        lost_revenue: lostRevenue,
        lost_revenue_usd: lostRevenueUsd,
        realized_revenue: realizedRevenue,
        realized_revenue_usd: realizedRevenueUsd,
        avg_completed_basket_size: avgCompletedBasketSize,
        avg_cancelled_basket_size: avgCancelledBasketSize,
      } = data || {};
      const timePeriodKey = getTimePeriodKey(timePeriod);
      const time = calculateTimePeriod(timePeriod, date);
      const dateIndex =
        dynamicComparisonData && dynamicComparisonData.length
          ? dynamicComparisonData.findIndex(dcd => dcd[timePeriodKey] === time)
          : -1;
      if (dateIndex > -1) {
        const hasGroup =
          dynamicComparisonData[dateIndex][`${groupByKey}`] &&
          dynamicComparisonData[dateIndex][`${groupByKey}`].length;
        const groups = hasGroup
          ? dynamicComparisonData[dateIndex][`${groupByKey}`]
          : [];
        const groupIndex = groups.findIndex(g => g[groupByKey] === groupKey);
        if (groupIndex > -1) {
          let targetGroup = {
            ...groups[groupIndex],
          };

          targetGroup = {
            ...targetGroup,
            completed_orders:
              (targetGroup.completed_orders || 0) + (completedOrders || 0),
            cancelled_orders:
              (targetGroup.cancelled_orders || 0) + (cancelledOrders || 0),
            totalOrders:
              (targetGroup.completed_orders || 0) +
              (completedOrders || 0) +
              (targetGroup.cancelled_orders || 0) +
              (cancelledOrders || 0),
            gross_revenue:
              (targetGroup.realized_revenue || 0) + (realizedRevenue || 0),
            gross_revenue_usd:
              (targetGroup.realized_revenue_usd || 0) +
              (realizedRevenueUsd || 0),
            lost_revenue: (targetGroup.lost_revenue || 0) + (lostRevenue || 0),
            lost_revenue_usd:
              (targetGroup.lost_revenue_usd || 0) + (lostRevenueUsd || 0),
            realized_revenue:
              (targetGroup.realized_revenue || 0) + (realizedRevenue || 0),
            realized_revenue_usd:
              (targetGroup.realized_revenue_usd || 0) +
              (realizedRevenueUsd || 0),
            avgCompletedBasketSize:
              (targetGroup.avgCompletedBasketSize || 0) +
              (avgCompletedBasketSize || 0),
            avgCancelledBasketSize:
              (targetGroup.avgCancelledBasketSize || 0) +
              (avgCancelledBasketSize || 0),
          };
          groups[groupIndex] = targetGroup;
        } else {
          groups.push({
            [timePeriodKey]: time,
            [groupByKey]: groupKey,
            completed_orders: completedOrders || 0,
            cancelled_orders: cancelledOrders || 0,
            total_orders: (completedOrders || 0) + (cancelledOrders || 0),
            gross_revenue: realizedRevenue || 0,
            gross_revenue_usd: realizedRevenueUsd || 0,
            lost_revenue: lostRevenue || 0,
            lost_revenue_usd: lostRevenueUsd || 0,
            realized_revenue: realizedRevenue || 0,
            realized_revenue_usd: realizedRevenueUsd || 0,
            avg_completed_basket_size: avgCompletedBasketSize || 0,
            avg_cancelled_basket_size: avgCancelledBasketSize || 0,
          });
        }
      } else {
        const dataEntry = {
          date,
          week: time,
          month: time,
        };
        dataEntry[groupByKey] = [
          {
            [timePeriodKey]: time,
            [groupByKey]: groupKey,
            completed_orders: completedOrders || 0,
            cancelled_orders: cancelledOrders || 0,
            total_orders: (completedOrders || 0) + (cancelledOrders || 0),
            gross_revenue: realizedRevenue || 0,
            gross_revenue_usd: realizedRevenueUsd || 0,
            lost_revenue: lostRevenue || 0,
            lost_revenue_usd: lostRevenueUsd || 0,
            realized_revenue: realizedRevenue || 0,
            realized_revenue_usd: realizedRevenueUsd || 0,
            avg_completed_basket_size: avgCompletedBasketSize || 0,
            avg_cancelled_basket_size: avgCancelledBasketSize || 0,
          },
        ];
        dynamicComparisonData.push(dataEntry);
      }
    });
  }
  return sortArrayByDate(dynamicComparisonData, 'date');
};

const updateBasketSizes = (providerComparisonData, selectedComparisonType) =>
  providerComparisonData.map(data => {
    const updatedProviders = data[selectedComparisonType].map(provider => {
      const {
        completed_orders,
        cancelled_orders,
        realized_revenue,
        lost_revenue,
      } = provider;

      const avgCompletedBasketSize =
        completed_orders !== 0 ? realized_revenue / completed_orders : 0;

      const avgCancelledBasketSize =
        cancelled_orders !== 0 ? lost_revenue / cancelled_orders : 0;

      return {
        ...provider,
        avg_completed_basket_size: avgCompletedBasketSize,
        avg_cancelled_basket_size: avgCancelledBasketSize,
      };
    });

    return {
      ...data,
      provider_id: updatedProviders,
    };
  });

const normalizeData = (data, key) =>
  data.reduce((acc, curr) => {
    const values = curr[key]; // Extract the values based on the key
    if (values) {
      return [...acc, ...values];
    }
    return acc;
  }, []);

const generateStackedBarChartData = (
  selectedComparisonTypeData,
  modifiedAnalyticsData,
  targetKey,
  groupByKey,
  timePeriod,
) => {
  const nData = normalizeData(modifiedAnalyticsData, groupByKey);
  const timePeriodKey = getTimePeriodKey(timePeriod);
  const timePeriodDataArray = modifiedAnalyticsData.map(m => m[timePeriodKey]);
  let chartData = [];
  let colorCodeMapping = '';
  if (selectedComparisonTypeData && selectedComparisonTypeData.length) {
    if (groupByKey === 'provider_id') {
      colorCodeMapping = ANALYTICS_PROVIDER_COMPARISON_COLOR_CODE;
    } else if (groupByKey === 'brand_id' || groupByKey === 'branch_id') {
      colorCodeMapping = ANALYTICS_CHART_COLORS;
    }
    chartData = selectedComparisonTypeData.map((item, index) => ({
      [groupByKey]: item.id, // Use the provided groupByKey (provider_id, brand_id, branch_id)
      color:
        groupByKey === 'provider_id'
          ? ANALYTICS_PROVIDER_COMPARISON_COLOR_CODE[item.id] || '#000000'
          : colorCodeMapping[index] || '#000000',
      name: item.title, // Assuming the item has a title property
      data: timePeriodDataArray.map(() => 0) || [],
    }));
    nData.forEach(nd => {
      const groupIndex = chartData.findIndex(
        cd => cd[groupByKey] === nd[groupByKey],
      );
      const timePeriodIndex = timePeriodDataArray.findIndex(
        tp => tp === nd[timePeriodKey],
      );
      const isRevenueKey = Object.values(REVENUE_KEYS).includes(targetKey);
      const isAvgBasketsKey =
        Object.values(AVG_BASKET_KEYS).includes(targetKey);

      if (chartData[groupIndex]) {
        chartData[groupIndex].data[timePeriodIndex] =
          isRevenueKey || isAvgBasketsKey
            ? (nd[targetKey] / 100).toFixed(2)
            : nd[targetKey];
      }
    });
  }
  return chartData;
};

const getAggregatedSummary = data => {
  if (!data || !data.length) return {};

  const summary = data.reduce((acc, curr) => {
    acc.completed_orders = [
      +(acc.completed_orders || 0) + curr.completed_orders,
    ];
    acc.cancelled_orders = [
      +(acc.cancelled_orders || 0) + curr.cancelled_orders,
    ];
    acc.total_orders = [+(acc.total_orders || 0) + curr.total_orders];
    acc.realized_revenue = (acc.realized_revenue || 0) + curr.realized_revenue;
    acc.lost_revenue = (acc.lost_revenue || 0) + curr.lost_revenue;
    acc.gross_revenue = (acc.realized_revenue || 0) + curr.realized_revenue;
    acc.net_revenue = (acc.net_revenue || 0) + curr.net_revenue;
    acc.currency = curr.currency;
    acc.currency_symbol = curr.currency_symbol;
    return acc;
  }, {});

  summary.realized_revenue = [+(summary.realized_revenue / 100).toFixed(2)];
  summary.lost_revenue = [+(summary.lost_revenue / 100).toFixed(2)];
  summary.gross_revenue = [+(summary.realized_revenue / 100).toFixed(2)];
  summary.net_revenue = [+(summary.net_revenue / 100).toFixed(2)];

  return summary;
};

const getStackedDonutChartDataForSeries = (data, key) => {
  if (!data || !data.length) {
    return [];
  }

  if (
    [
      ORDER_COUNT_KEYS.COMPLETED_ORDERS,
      ORDER_COUNT_KEYS.CANCELLED_ORDERS,
    ].includes(key)
  ) {
    return data.map(d => d[key]);
  }

  if (key === ORDER_COUNT_KEYS.TOTAL_ORDERS) {
    return data.map(
      d =>
        d[ORDER_COUNT_KEYS.COMPLETED_ORDERS] +
        d[ORDER_COUNT_KEYS.CANCELLED_ORDERS],
    );
  }

  if (key === ORDER_CANCELLATION_RATE_KEY) {
    const totalOrders = data.reduce(
      (total, d) => total + d[ORDER_COUNT_KEYS.TOTAL_ORDERS],
      0,
    );
    return data.map(d => {
      const completed = d[ORDER_COUNT_KEYS.COMPLETED_ORDERS] || 0;
      const cancelled = d[ORDER_COUNT_KEYS.CANCELLED_ORDERS] || 0;
      const total = completed + cancelled;
      return total === 0 ? 0 : (cancelled / totalOrders) * 100;
    });
  }

  return data.map(d => +(d[key] / 100).toFixed(2));
};

const getStackedDonutChartDataForLabels = (data, providers) => {
  if (!data || !data.length || !providers || !providers.length) {
    return [];
  }
  return data.map(d => {
    const provider = providers.find(p => p.id === d.provider_id);
    return provider ? provider.title : null;
  });
};

const getStackedDonutChartDataForColors = data => {
  const donutData = data && data.length ? data : [];
  return donutData
    .map(d => ANALYTICS_PROVIDER_COMPARISON_COLOR_CODE[d.provider_id])
    .filter(color => color !== undefined);
};

const exportedCsvHeaderTitleForXAxis = selectedTimePeriod => {
  if (selectedTimePeriod === WEEKLY_TIME_PERIOD) return 'Week';
  if (selectedTimePeriod === MONTHLY_TIME_PERIOD) return 'Month';
  return 'Date';
};

const getIntervalAndMax = maxNum => {
  const power = parseInt(Math.log10(+maxNum), 10);
  const interval = 10 ** power;
  const yAxisMaxValue =
    +maxNum % interval !== 0 ? (+maxNum / interval + 1) * interval : +maxNum;
  const yAxisTickAmount = yAxisMaxValue / interval;
  return { yAxisMaxValue, yAxisTickAmount };
};

const generateProviderSpecificTableData = data => {
  if (!data || data.length === 0) {
    return null;
  }
  const providerIds = new Set();
  const providerQuantities = {};
  const providerRevenues = {};
  const providerRevenuesInUsd = {};
  data.forEach(item => {
    providerQuantities[item.title] = {};
    providerRevenues[item.title] = {};
    providerRevenuesInUsd[item.title] = {};
    item.providers.forEach(provider => {
      providerIds.add(provider.provider_id);
      providerQuantities[item.title][provider.provider_id] = provider.quantity;
      providerRevenues[item.title][provider.provider_id] = provider.revenue;
      providerRevenuesInUsd[item.title][provider.provider_id] =
        provider.revenue_usd;
    });
  });
  const providerTitleRow = {
    providerQuantitiesTitle: Array.from(providerIds),
    providerRevenuesTitle: Array.from(providerIds),
  };
  const providerDataRow = data.map(item => {
    const providerQuantitiesArray =
      providerTitleRow.providerQuantitiesTitle.map(
        providerId => providerQuantities[item.title][providerId] || 0,
      );
    const providerRevenuesArray = providerTitleRow.providerRevenuesTitle.map(
      providerId => providerRevenues[item.title][providerId] || 0,
    );
    const providerRevenuesInUsdArray =
      providerTitleRow.providerRevenuesTitle.map(
        providerId => providerRevenuesInUsd[item.title][providerId] || 0,
      );
    return {
      title: item.title,
      quantity: item.quantity,
      revenue: item.revenue,
      revenueUsd: item.revenue_usd,
      providerQuantities: providerQuantitiesArray,
      providerRevenues: providerRevenuesArray,
      providerRevenuesInUsd: providerRevenuesInUsdArray,
    };
  });
  const uniqueProvidersLength = providerIds.size;
  return {
    providerTitleRow,
    providerDataRow,
    uniqueProvidersLength,
  };
};

const getSortFunction = (data, sortBy) => {
  if (!data || !Array.isArray(data) || !data[0] || typeof sortBy !== 'string') {
    return () => 0;
  }
  if (typeof data[0][sortBy] === 'string') {
    if (isValid(parseISO(data[0][sortBy]))) {
      // Check if it's a valid date string
      return (a, b) => {
        const aValue = parseISO(a[sortBy]);
        const bValue = parseISO(b[sortBy]);
        return compareAsc(aValue, bValue);
      };
    }
    return (a, b) => {
      const aValue = (a[sortBy] || '').toString().trim();
      const bValue = (b[sortBy] || '').toString().trim();
      return aValue.localeCompare(bValue, undefined, { sensitivity: 'base' });
    };
  }
  if (typeof data[0][sortBy] === 'number') {
    return (a, b) => a[sortBy] - b[sortBy];
  }
  return () => 0;
};

const sortProviderSpecificDataRow = (data, sortBy, sortOrderType) => {
  if (!data || !data.providerDataRow) {
    return { providerDataRow: [], sortBy, sortOrderType };
  }
  const sortFunction = getSortFunction([...data.providerDataRow], sortBy);
  const sortedData = [...data.providerDataRow].sort(sortFunction);
  if (sortOrderType === 'desc') {
    sortedData.reverse();
  }
  return {
    ...data,
    providerDataRow: sortedData,
    sortBy,
    sortOrderType: sortOrderType === 'asc' ? 'desc' : 'asc',
  };
};

function sortAggregatedDataRow(data = [], sortBy, sortOrder) {
  if (!sortBy || !sortOrder) {
    return data;
  }
  const sortFunction = getSortFunction(data, sortBy);
  const sortedData = [...data].sort(sortFunction);
  if (sortOrder === 'desc') {
    sortedData.reverse();
  }
  return sortedData;
}

const calculateDiscountTotal = discountData => {
  const discountTotal = {
    provider_name: 'All',
    currency_symbol:
      discountData[0] && discountData[0].currency_symbol !== undefined
        ? discountData[0].currency_symbol
        : '',
    currency:
      discountData[0] && discountData[0].currency !== undefined
        ? discountData[0].currency
        : '',
    ...Object.values(DISCOUNT_KEYS).reduce((acc, key) => {
      let total = 0;
      discountData.forEach(item => {
        total += item[key] || 0;
      });
      return { ...acc, [key]: total };
    }, {}),
  };

  return [discountTotal];
};

const calculateDiscountProviderSpecific = (discountData, providers) => {
  const providerSpecificDiscount = discountData.map(item => {
    const provider = providers.find(p => p.id === item.provider_id);

    return {
      provider_name: provider ? provider.title : 'Unknown',
      currency_symbol: item.currency_symbol || '',
      currency: item.currency || '',
      discount: item.discount,
      merchant_discount: item.merchant_discount,
      provider_discount: item.provider_discount,
    };
  });

  return providerSpecificDiscount;
};

const IncorrectMerChantDiscountProviders = [
  PROVIDERS.UBEREATS,
  PROVIDERS.SHOPEE,
  PROVIDERS.DEMAECAN,
  PROVIDERS.DELIVEROO,
  PROVIDERS.JUSTEAT,
  PROVIDERS.DOORDASH,
  PROVIDERS.FOODPANDA,
  PROVIDERS.SHOPIFY,
  PROVIDERS.ODDLE,
  PROVIDERS.PICKAROO,
  PROVIDERS.WOLT,
];

function generateDiscountCSVData(csvHeaderData, csvRowData) {
  const output = [
    Object.fromEntries(
      csvHeaderData.map((obj, index) => [index.toString(), obj.title]),
    ),
  ];

  const keysOrder = [
    'provider_name',
    DISCOUNT_KEYS.MERCHANT_DISCOUNT,
    DISCOUNT_KEYS.PROVIDER_DISCOUNT,
    DISCOUNT_KEYS.TOTAL_DISCOUNT,
    'currency',
  ];

  csvRowData.forEach(obj => {
    const transformedData = {};
    keysOrder.forEach(key => {
      if (
        key === DISCOUNT_KEYS.MERCHANT_DISCOUNT ||
        key === DISCOUNT_KEYS.PROVIDER_DISCOUNT ||
        key === DISCOUNT_KEYS.TOTAL_DISCOUNT
      ) {
        transformedData[key] = obj[key];
      } else {
        transformedData[key] = obj[key];
      }
    });
    output.push(transformedData);
  });

  return output;
}

export const generatePerformanceCSVData = (csvHeader, performanceData) => {
  const { performanceRowData, grandTotalRevenue, grandTotalOrders } =
    performanceData;

  const output = [csvHeader];

  performanceRowData.forEach(row => {
    const csvRow = {
      name: row.name,
      net_sales: centToMoney(row.netSales),
      net_sales_cent: row.netSales,
      total_orders: row.totalOrder,
    };
    output.push(csvRow);
  });

  const grandTotalRow = {
    name: 'Grand Total',
    net_sales: centToMoney(grandTotalRevenue),
    net_sales_cent: grandTotalRevenue,
    total_orders: grandTotalOrders,
  };

  output.push(grandTotalRow);

  return output;
};

function generatePaymentMethodCSVData(csvHeaderData, csvRowData) {
  const output = [csvHeaderData];

  const grandTotal = {
    payment_method: 'Grand Total',
    total_orders: 0,
    gross_revenue: 0,
    gross_revenue_cent: 0,
    discount: 0,
    discount_cent: 0,
    avg_basket_size: 0,
    avg_basket_size_cent: 0,
  };

  const csvHeaderKeys = Object.keys(csvHeaderData);

  csvRowData.forEach(row => {
    const mainRow = {};
    csvHeaderKeys.forEach(key => {
      if (['gross_revenue', 'discount', 'avg_basket_size'].includes(key)) {
        mainRow[key] = centToMoney(row[key]);
        mainRow[`${key}_cent`] = row[key];
      } else if (!Object.hasOwn(mainRow, key)) {
        mainRow[key] = row[key];
      }
    });
    output.push(mainRow);

    grandTotal.total_orders += row.total_orders;
    grandTotal.gross_revenue += row.gross_revenue;
    grandTotal.gross_revenue_cent += row.gross_revenue;
    grandTotal.discount += row.discount;
    grandTotal.discount_cent += row.discount;
    grandTotal.avg_basket_size += row.avg_basket_size;
    grandTotal.avg_basket_size_cent += row.avg_basket_size;

    if (row.channel_analytics) {
      row.channel_analytics.forEach(channel => {
        const channelRow = {};
        csvHeaderKeys.forEach(key => {
          if (key === 'payment_method') {
            channelRow[key] = channel.payment_channel;
          } else if (
            ['gross_revenue', 'discount', 'avg_basket_size'].includes(key)
          ) {
            channelRow[key] = centToMoney(channel[key]);
            channelRow[`${key}_cent`] = channel[key];
          } else if (!Object.hasOwn(channelRow, key)) {
            channelRow[key] = channel[key];
          }
        });
        output.push(channelRow);
      });
    }
  });

  const grandTotalRow = {};
  csvHeaderKeys.forEach(key => {
    if (['gross_revenue', 'discount', 'avg_basket_size'].includes(key)) {
      grandTotalRow[key] = centToMoney(grandTotal[key]);
      grandTotalRow[`${key}_cent`] = grandTotal[key];
    } else if (!Object.hasOwn(grandTotalRow, key)) {
      grandTotalRow[key] = grandTotal[key];
    }
  });
  output.push(grandTotalRow);

  return output;
}

function generatePauseStoreLogCSVData(
  headerKeys,
  tableRowData,
  selectedBranch,
  brands,
) {
  const branchMap = selectedBranch.reduce((accumulator, branch) => {
    accumulator[branch.id] = branch.title;
    return accumulator;
  }, {});

  const brandMap = brands.reduce((accumulator, brand) => {
    accumulator[brand.id] = brand.title;
    return accumulator;
  }, {});

  const headerRow = headerKeys.reduce((accumulator, current) => {
    accumulator[current.key] = current.title;
    return accumulator;
  }, {});
  const output = [headerRow];

  tableRowData.forEach(row => {
    const mainRow = {};
    headerKeys.forEach(({ key }) => {
      if (['branch_id'].includes(key)) {
        mainRow[key] = branchMap[row[key]];
      } else if (['brand_id'].includes(key)) {
        mainRow[key] = brandMap[row[key]];
      } else {
        mainRow[key] = row[key];
      }
    });
    output.push(mainRow);
  });

  return output;
}

const generateBrandWiseBusyLogData = busyModeData => {
  const brandWiseBusyLogData = [];
  if (isEmpty(busyModeData)) return brandWiseBusyLogData;
  busyModeData.forEach(data => {
    const { brand_ids: brandIds = [], ...rest } = data;
    const tempData = brandIds.map(brandId => ({
      ...rest,
      brand_id: brandId,
    }));
    brandWiseBusyLogData.push(...tempData);
  });
  return brandWiseBusyLogData;
};

export const generateBusyModeReasonData = (
  busyModeLogData,
  branches,
  brands,
) => {
  const { logs } = busyModeLogData;
  const data = {};

  logs.forEach(item => {
    const { busy_mode_reason: reason } = item;
    if (isEmpty(reason)) return;
    if (isEmpty(data[reason])) {
      data[reason] = {
        branches: [],
        brands: [],
      };
    }
    const matchingBranch = branches.find(
      branch => branch.id === item.branch_id,
    );
    const reasonBranch = data[reason].branches.find(
      branch => branch.id === item.branch_id,
    );
    if (isEmpty(reasonBranch)) {
      data[reason].branches.push({
        id: item.branch_id,
        title: matchingBranch.title,
        count: 1,
      });
    } else {
      reasonBranch.count += 1;
    }

    item.brand_ids.forEach(brandId => {
      const matchingBrand = brands.find(brand => brand.id === brandId);
      const reasonBrand = data[reason].brands.find(
        brand => brand.id === brandId,
      );
      if (isEmpty(reasonBrand)) {
        data[reason].brands.push({
          id: brandId,
          branchId: item.branch_id,
          title: matchingBrand.title,
          count: 1,
        });
      } else {
        reasonBrand.count += 1;
      }
    });
  });

  return data;
};

export const generateCancellationReasonData = ordersData => {
  const { orders = [] } = ordersData;
  const data = {};

  orders.forEach(item => {
    const { cancellation_reason: reason } = item;
    if (isEmpty(reason)) return;
    if (isEmpty(data[reason])) {
      data[reason] = {
        branches: [],
        brands: [],
        providers: [],
      };
    }
    const reasonBranch = data[reason].branches.find(
      branch => branch.id === item.branch_id,
    );
    if (isEmpty(reasonBranch)) {
      data[reason].branches.push({
        id: item.branch_id,
        title: item.branch_title,
        count: 1,
      });
    } else {
      reasonBranch.count += 1;
    }

    const reasonBrand = data[reason].brands.find(
      brand => brand.id === item.brand_id,
    );
    if (isEmpty(reasonBrand)) {
      data[reason].brands.push({
        id: item.brand_id,
        title: item.brand_title,
        count: 1,
      });
    } else {
      reasonBrand.count += 1;
    }

    const reasonProvider = data[reason].providers.find(
      provider => provider.id === item.providerID,
    );
    if (isEmpty(reasonProvider)) {
      data[reason].providers.push({
        id: item.providerID,
        title: item.provider_title,
        count: 1,
      });
    } else {
      reasonProvider.count += 1;
    }
  });

  return data;
};

export const getFlatPaymentMethodData = (rows, selectedComparisonType) => {
  const flatData = [];

  rows.forEach(row => {
    const { channel_analytics: ca = [] } = row;

    ca.forEach(channel => {
      const { provider_analytics: pa = [] } = channel;
      const isCombo = ca.length > 1;
      if (selectedComparisonType === COMPARISON_TYPE.AGGREGATOR) {
        pa.forEach(p =>
          flatData.push({ ...p, isCombo, payment_method: row.payment_method }),
        );
      } else {
        flatData.push({
          ...channel,
          isCombo,
          payment_method: row.payment_method,
        });
      }
    });
  });

  return flatData;
};

export const generateEntityWiseAnalyticsData = (analyticsData, entityKey) =>
  analyticsData.reduce((allStores, store) => {
    const updatedData = { ...allStores };
    if (isEmpty(updatedData[store[entityKey]])) {
      updatedData[store[entityKey]] = [];
    }
    updatedData[store[entityKey]].push(store);
    return updatedData;
  }, {});

export const getNonZeroStackedAxisSeries = (xAxis, yAxis) => {
  const filteredXAxis = [];
  const filteredYAxis = yAxis.map(item => ({ ...item, data: [] }));

  xAxis.forEach((x, i) => {
    const isAllZero = yAxis.every(item => item.data[i] === 0);
    if (!isAllZero) {
      filteredXAxis.push(x);
      yAxis.forEach((item, idx) => filteredYAxis[idx].data.push(item.data[i]));
    }
  });

  return { xAxisSeries: filteredXAxis, yAxisSeries: filteredYAxis };
};

export const getNonZeroAxisSeries = (xAxis, yAxis) => {
  const filteredXAxis = [];
  const filteredYAxis = [];

  xAxis.forEach((x, i) => {
    if (yAxis[i] !== 0) {
      filteredXAxis.push(x);
      filteredYAxis.push(yAxis[i]);
    }
  });

  return { xAxisSeries: filteredXAxis, yAxisSeries: filteredYAxis };
};

export const calculateStoreCumulativeTotal = (
  analyticsData = [],
  filters = {},
  targetKey = '',
) => {
  const storeBrands =
    !isEmpty(analyticsData) && !isEmpty(filters)
      ? analyticsData.filter(store => {
          const filtersArr = Object.entries(filters);
          return filtersArr.every(([key, value]) => store[key] === value);
        })
      : analyticsData;
  return storeBrands.reduce((acc, curr) => acc + (curr[targetKey] || 0), 0);
};

export const checkShouldShowUsd = analyticsData => {
  const currencyCodeList = !isEmpty(analyticsData)
    ? analyticsData.map(b => b.currency)
    : [];
  return [...new Set(currencyCodeList)].length > 1;
};

export const getAnalyticsCurrencyObj = analyticsData => {
  const branchCurrencyObj = !isEmpty(analyticsData)
    ? {
        code: analyticsData[0].currency,
        symbol: analyticsData[0].currency_symbol,
      }
    : {};
  const shouldShowUsd = checkShouldShowUsd(analyticsData);
  return shouldShowUsd ? DEFAULT_CURRENCY_OBJ : branchCurrencyObj;
};

export const generatePerformanceAnalyticsData = (
  analyticsData,
  entityWiseAnalyticsData,
  selectedEntity,
) => {
  const shouldShowUsd = checkShouldShowUsd(analyticsData);

  const targetRevenueKey = shouldShowUsd
    ? REVENUE_KEYS.REALIZED_REVENUE_USD
    : REVENUE_KEYS.REALIZED_REVENUE;

  const performanceRowData = selectedEntity.reduce((dataRows, brand) => {
    const netSales = calculateStoreCumulativeTotal(
      entityWiseAnalyticsData[brand.id],
      null,
      targetRevenueKey,
    );
    const totalOrder = calculateStoreCumulativeTotal(
      entityWiseAnalyticsData[brand.id],
      null,
      ORDER_COUNT_KEYS.COMPLETED_ORDERS,
    );

    if (!netSales && !totalOrder) return dataRows;

    dataRows.push({
      name: brand.title,
      netSales,
      totalOrder,
    });

    return dataRows;
  }, []);

  const grandTotalRevenue = calculateStoreCumulativeTotal(
    performanceRowData,
    null,
    'netSales',
  );

  const grandTotalOrders = calculateStoreCumulativeTotal(
    performanceRowData,
    null,
    'totalOrder',
  );

  return { performanceRowData, grandTotalRevenue, grandTotalOrders };
};

export {
  calculateDiscountProviderSpecific,
  calculateDiscountTotal,
  exportedCsvHeaderTitleForXAxis,
  findDateKey,
  generateBrandWiseBusyLogData,
  generateComparisonData,
  generateDiscountCSVData,
  generatePauseStoreLogCSVData,
  generatePaymentMethodCSVData,
  generateProviderSpecificTableData,
  generateStackedBarChartData,
  getAggregatedSummary,
  getCumulativeSumForDailyData,
  getCumulativeSumForMonthlyData,
  getCumulativeSumForWeeklyData,
  getDailyData,
  getIntervalAndMax,
  getMonthName,
  getStackedDonutChartDataForColors,
  getStackedDonutChartDataForLabels,
  getStackedDonutChartDataForSeries,
  IncorrectMerChantDiscountProviders,
  sortAggregatedDataRow,
  sortProviderSpecificDataRow,
  updateBasketSizes,
};
