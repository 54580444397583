export const SUMMARY_HELP = `This section summarizes your performance.
  Completed Orders is the total number of completed orders, while cancelled orders is the total number of orders placed that were not completed.
  Total Orders is the number of all orders, whether completed or cancelled.
  Gross Order Value is the amount earned from completed orders, while Lost Revenue is the amount foregone from cancelled orders.
  `;

export const GROSS_REVENUE_HELP =
  'Gross Order Value is the base amount of the order, without adjustments for discounts or delivery fees.';

export const REALIZED_VS_LOST_HELP =
  'Realized Revenue is the gross revenue received from completed orders, while Lost Revenue is the gross revenue foregone due to cancelled orders. ';

export const GROSS_VS_LOST_HELP = `Gross Order Value is the total order amount earned from completed orders. Lost Revenue is the order amount foregone from cancelled orders.
  Note these order amounts do not account for discounts, aggregator commissions, or delivery fees.
  `;
export const COMPLETED_VS_CANCELLED_HELP = `Completed orders is the number of orders delivered to the customer.
  Cancelled orders is the number of orders not completed, due to cancellation either by the customer or the aggregator.
  `;

export const ORDER_BREAKDOWN_HELP =
  'The Order Breakdown table shows comprehensive details on each order made, aside from what was previously mentioned. Data such as time, order ID, and menu items can be viewed.';

export const AGGREGATOR_COMPARISON_HELP =
  'When the Aggregator Comparison is toggled on, performance across different delivery aggregators are compared.';
export const BRAND_COMPARISON_HELP =
  'When the Brand Comparison is toggled on, performance across different brands are compared.';
export const BRANCH_COMPARISON_HELP =
  'When the Brand Comparison is toggled on, performance across different branches are compared.';

export const DATE_RANGE_HELP =
  'Date Range filters your data for selected start and end dates.';

export const TIME_RANGE_HELP = `Time Range filters your data for selected start and end times.`;

export const TIME_PERIOD_HELP =
  'Time Period splits your data into specific lengths of time.';
export const METRICS_HELP =
  'Active indicates that a Brand, Branch, Aggregator, or Market completed at least one order during the selected period.';
export const DISCOUNT_HELP = `This section summarizes discounts applied to your orders per aggregator.
  Merchant shouldered discount is the discount amount paid by the business.
  Aggregator shouldered discount is the discount amount paid by the aggregator.
  `;
export const AVG_BASKET_SIZE_HELP =
  'Avg Basket Size is the average value of any delivered order.';
export const ITEM_TABLE_BREAKDOWN_HELP =
  'This table breaks down how much of each menu category, menu item, or modifier has been ordered and how much gross sales has been generated.';
export const BUSY_MODE_CHART_HELP =
  'This is the total number of minutes that stores have been paused on klikit Cloud.';
export const BUSY_MODE_TABLE_HELP =
  'This is a list of each time a store has been paused.';
export const PAYMENT_METHOD_HELP =
  'The list of items defined filter type is for webshop and add order';

export const PAYMENT_METHOD_GROSS_REVENUE_HELP = `Gross Order Value is the total order amount earned from completed orders.`;
export const PAYMENT_METHOD_DISCOUNT_HELP = `This section summarizes discounts applied to your orders per aggregator. Merchant shouldered discount is the discount amount paid by the business. Aggregator shouldered discount is the discount amount paid by the aggregator.`;
export const PAYMENT_METHOD_BASKET_SIZE_HELP = `Avg Basket Size is the average value of any delivered order.`;
export const OOS_HELP = `Shows report of items and modifiers that are out of stock.`;
